import { init as folksSelectorInit } from './folks-selector.js';
import { init as searchBlockPopupButtonsInit } from './search-block-popup-buttons.js';
import { default as Mustache } from "./mustache.js"

let template = $('#folkSelectorTemplate');

const elements = {

	target: $('.js-search-block-wrapper'),
	multi: $('.js-search-block-wrapper-multi'),
	single: $('.js-search-block-wrapper-single')
};

const init = function () {
	if (!elements.target.length || !elements.single.length || !elements.multi.length || !template.length) return;

	template = template.html();

	elements.target.each(function () {

		var target = $(this),
			multiroom = target.find('script[data-type="multiroom"]'),
			singleroom = target.find('script[data-type="singleroom"]');

		if (!multiroom.length || !singleroom.length) return;

		multiroom = jQuery.parseJSON(multiroom.text());
		singleroom = jQuery.parseJSON(singleroom.text());

		var multiroomRendered = Mustache.render(template, multiroom),
			singleRoomRendered = Mustache.render(template, singleroom);

		target.find('.js-search-block-wrapper-multi').html(multiroomRendered);
		target.find('.js-search-block-wrapper-single').html(singleRoomRendered);

	});

	// initialise folk selector
	folksSelectorInit(true);

	// intialise search block popup buttons
	searchBlockPopupButtonsInit();
};

export default init;
export { init }
